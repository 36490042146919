import './jquery-global';
import '../../../../node_modules/jquery-validation/dist/jquery.validate.min';

export default class FormValidator{

	constructor(path){
		this.$form = $(path);
	}

	buildValidationObject($form){
        var $fields = $('.js-validate', $form),
            ret = {};

        $fields.each(function(i, v){
            var $f = $(v),
                obj = {},
                nested = {};

            if($f.hasClass('js-required')){
                nested = Object.assign(nested, { required: true });
            }

            if($f.hasClass('js-valuenotequals')){
                nested = Object.assign(nested, { valueNotEquals: $f.data('notequals') });
            }

            if($f.hasClass('js-email')){
                nested = Object.assign(nested, { email: true });
            }

            if($f.hasClass('js-maxlength')){
                nested = Object.assign(nested, { maxlength: $f.data('maxlength') });
            }

            if($f.hasClass('js-minlength')){
                nested = Object.assign(nested, { minlength: $f.data('minlength') });
            }

            if($f.hasClass('js-number')){
                nested = Object.assign(nested, { valueNotMatches: /^\d+(\.\d{0,2})?$/g });
            }

            obj[$(v).attr('name')] = nested;

            Object.assign(ret, obj);
        });
        return ret;
    }

    initValidate(){

        $.validator.addMethod(
            "valueNotEquals", 
            function(value, element, arg){
                return arg !== value;
            }, 
            "Value must not equal arg."
        );

        $.validator.addMethod(
            "valueNotMatches",
            function(value, element, arg){
                let re = new RegExp(arg);
                console.log(value, arg, re.test(value));
                return re.test(value);
            },

        );

        Object.assign($.validator.messages, {
            required: "Campo obbligatorio.",
            remote: "Valore non valido.",
            email: "Email non valida.",
            url: "URL non valido.",
            date: "Data non valida.",
            dateISO: "Please enter a valid date (ISO).",
            number: "Numero non valido.",
            digits: "Valore non valido.",
            creditcard: "Please enter a valid credit card number.",
            equalTo: "Please enter the same value again.",
            accept: "Please enter a value with a valid extension.",
            maxlength: $.validator.format("Inserisci al massimo {0} caratteri."),
            minlength: $.validator.format("Inserisci almeno {0} caratteri."),
            rangelength: $.validator.format("Inserisci un valore fra {0} e {1} caratteri."),
            range: $.validator.format("Inserisci un valore fra {0} e {1}."),
            max: $.validator.format("Inserisci un valore minore o uguale a {0}."),
            min: $.validator.format("Inserisci un valore maggiore o uguale {0}."),
            valueNotEquals: $.validator.format("Valore non valido"),
            valueNotMatches: $.validator.format("Valore non valido")

        });

        $.validator.messages.required = "* Campo obbligatorio.";
    	$.validator.messages.email = "* Valore non valido."; 

        var me = this,
            $form = me.$form,
            form = $form.get(0),
            rules;
        if(!!$form.length){
            rules = this.buildValidationObject($form);
            $form.validate({
                ignore: [],
                errorElement: 'p',
                errorClass: 'error',
                errorPlacement: function (error, element) {
                    $(element).parents('.form-group').find('.form-control-feedback').html(error);
                },
                rules: rules,
                submitHandler: function(form){
                    $form.find('.submitBtn').attr('disabled',true);
                    form.submit();
                }
            });
        }
    }
}