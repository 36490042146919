import './jquery-global';

export default class AutoComplete{
    constructor(formSelector, fieldSelector = '.js-autoComplete'){
        this.formSelector = formSelector;
        this.$form = $(formSelector);
        this.selector = fieldSelector;
        this.autoCompleteInit();
    }

    highlight(direction, $el){
        var $wrapper = $el.closest('.form-group'),
            selected = $('.selectable.focused', $wrapper),
            selectedRect, scrollable, scrollableRect, list, listRect;

        if(!selected.length){
            selected = direction === 'down' ? $('.selectable', $wrapper).first() : $('.selectable', $wrapper).last();
            selected.addClass('focused');
        }else{
            selected.removeClass('focused');
            if(direction === 'down'){
                selected = selected.nextAll('.selectable').first().length ? selected.nextAll('.selectable').first() : $('.selectable', $wrapper).first();
            }else{
                selected = selected.prevAll('.selectable').first().length ? selected.prevAll('.selectable').first() : selected.nextAll('.selectable').last()
            }
            selected.addClass('focused');
        }

        list = selected.get(0).parentElement;
        listRect = list.getBoundingClientRect();

        selectedRect = selected.get(0).getBoundingClientRect();
        
        scrollable = selected.get(0).parentElement.parentElement;/* .combo */
        scrollableRect = scrollable.getBoundingClientRect();
        
        if(selectedRect.bottom > scrollableRect.bottom){
            scrollable.scroll(0, selectedRect.y - listRect.y - scrollableRect.height + selectedRect.height);
        }else if(selectedRect.top < scrollableRect.top){
            scrollable.scroll(0, selectedRect.y - listRect.y);
        }
    }
    select($el){
        var me = this,
            $wrapper = $el.closest('.form-group'),
            $selected = $wrapper.find('.listItem.focused'),
            $fakeInput = $wrapper.find('.fakeInputText');

        if(!!$selected.length){
            $el.val($selected.data('val'));
            $el.data('val', $selected.data('val'));
            $fakeInput.val($selected.html());
            $fakeInput.blur();
            if($el.hasClass('remoteAfterSelect')){
                let related = $(`[name=${$el.data('field_compile')}]`);
                this.remote(
                    related,
                    $el.data('val')
                ).then(function(response){
                    related.get(0).dataset.resultset = JSON.stringify(response);
                    related.trigger('change', [response]);
                    me.fillCombo($el.data('field_compile'), response);
                }).catch(function(e){
                    console.log(e);
                    $el.trigger('error', [e]);
                });
            }else{
                $el.get(0).dataset.resultset = JSON.stringify($selected.data('resultset'));
                $el.trigger('selected');
            }
        }
    }

    remote($el, relatedVal){
        var url = $el.data('url');

        if(!!$el.data('lookup_field')){
            url =`${url}?${$el.data('lookup_field')}=${!!relatedVal ? relatedVal : $el.val()}`;
        }
        return new Promise(function(resolve, reject){
            $.ajax({
                url: url,
            }).done(function(response){
                resolve(response);
            }).fail(function(response){
                reject(response);
            })
        });
    }

    filter($field, $el){
        var $wrapper = $el.closest('.form-group'),
            $items = $wrapper.find('.listItem');
        
        $el.val("")
        $items.each(function(i,v){
            $(v).removeClass('focused').removeClass('hidden').removeClass('selectable');
        });
        
        $items.each(function(i,v){
            var $v = $(v),
                val = $v.html().toLowerCase();
            if(val.indexOf($field.val().toLowerCase()) === -1){
                $v.addClass('hidden');
            }else($v.removeClass('hidden').addClass('selectable'));
        });
    }

    append($el, obj, $originalField){
        var me = this;
        var element = document.createElement('div');
        if(!!obj.value){
            element.dataset.val = obj.value;
            element.addEventListener('mousedown', function(e){
                $el.closest('.form-group').find('.focused').removeClass('focused');
                $(e.currentTarget).addClass('focused');
                me.select($originalField);
            });
            element.classList.add('listItem');
            element.classList.add('selectable');
            element.innerHTML = obj.html;
            $el.get(0).append(element);
        }
    }

    handleChange($el){
        $el.closest('.form-group').find('.fakeInputText').removeAttr('disabled');
        if(!!$el.find('option:selected').attr('value')){
            $el.closest('.form-group').find('.fakeInputText').val($el.find('option:selected').html())
            if($el.hasClass('js-conditionalDisable') && $el.val() === $el.data('conditional_disable')){
                $el.closest('.form-group').find('.fakeInputText').prop('disabled', 'disabled');
            }
        }else{
            /*console.log($el.prop('name'), 'empty value');*/
            $el.closest('.form-group').find('.fakeInputText').val("")
        }
    }

    fillCombo(fieldName, data){
        var me = this,
            $field = $(`[name=${fieldName}]`),
            $w = $field.closest('.form-group').find('.comboWrapper');

        $w.find('.list').html("");
        data.forEach((o)=>{
            me.append($w.find('.list'), { value: o.name, html: o.name }, $field)
        });
    }

    autoCompleteInit(){
        var me = this,
            selector = this.selector,
            fields = $(selector);

        $.each(fields, function(i,v){
            var $el = $(v),
                name = '_' + $el.attr('name'),
                placeholder = $el.closest('.form-group').find('label').html(),
                $w = $('<div class="comboWrapper" />'),
                $input,
                timeout;
            $el.closest('.form-group').addClass('autoComplete');
            $w.append('<div class="fieldWrapper"><input type="text" id="id_'+name+'" class="form-control fakeInputText" name="'+name+'" placeholder="'+placeholder+'" autocomplete="2890tasf" /><div class="combo hidden"><div class="list"></div></div></div>');
            $el.find('option').each(function(n, opt){
                var $o = $(opt)
                if(!!$o.attr('value')){
                    this.append($w.find('.list'), { value: $o.attr('value'), html: $o.html() }, $el)
                }
            });
            $input = $w.find('[name='+name+']');

            $input.on('focus', function(e){
                $input.next().removeClass('hidden');
            });
            $input.on('blur', function(e){
                $input.next().addClass('hidden');
                $el.closest('.form-group').find('.listItem').each(function(i,v){
                    $(v).removeClass('hidden');
                })
                switch($el.get(0).tagName.toLowerCase()){
                    case 'input':
                    $el.val($input.val());
                    break;
                }
            });

            $el.on('change', function(e){
                me.handleChange($el);
            });

            me.handleChange($el);

            $input.on('keyup', function(e){
                switch(e.keyCode){
                    case 13:
                    //console.log('Enter');
                    me.select($el);
                    break;
                    case 40:
                    //console.log('ArrowDown');
                    me.highlight('down', $el);
                    break;
                    case 38:
                    //console.log('ArrowUp');
                    me.highlight('up', $el);
                    break;
                    default: 
                    if (/[a-zA-Z0-9-_ ]/.test($input.val())){
                        me.filter($input, $el);
                    }
                    break;
                }
            });

            $w.insertBefore($el.closest('.form-group').find('.form-control-feedback'));
            if($el.hasClass('autoFill')){
                me.remote(
                    $el
                ).then((results)=>{
                    results.forEach((a)=>{
                        me.append($el.closest('.form-group').find('.list'), { value: a.province_code, html: a.province }, $el);
                    })
                }).catch((err)=>{
                    console.log(err);
                })
            }
            switch($el.get(0).tagName.toLowerCase()){
                case 'select':
                if(!!$el.find('option:selected').length && !!$el.find('option:selected').prop('value') ){
                    $el.closest('.form-group').find('.fakeInputText').val($el.find('option:checked').html());
                }
                break;
                case 'input':
                if(!!$el.val()){
                    $el.closest('.form-group').find('.fakeInputText').val($el.val());   
                }
                break;

            }
        });

        if(!!$(me.formSelector).length){
            document.querySelector(me.formSelector).onkeypress = function(e) {
                 var key = e.charCode || e.keyCode || 0;     
                 if (key == 13) {
                    return false;
                 }
            }
        }
    }
}