/* Fix for old jquery modules import */
import './local/jquery-global';
import '../../../node_modules/rrssb/js/rrssb.js';

import AutoComplete from './local/AutoComplete';
import FormValidator from './local/FormValidator';

$(document).ready(function(){
    if(VARS.anchor){
        $(document.body).animate({
            'scrollTop':   $('#{{ anchor }}').offset().top
        }, 1000);
    }

    var title = !!$('meta[property="og:title"]').length ? $('meta[property="og:title"]').attr('content') : window.title,
        $link = $('.rrssb-email a'),
        subject = $link.data('subject'),
        description = $link.data('description');

    $('.rrssb-buttons').rrssb({
        // required:
        title: title,
        url: !!$('meta[property="og:url"]').length ? $('meta[property="og:url"]').attr('content') : window.share_url,
        image: !!$('meta[property="og:image"]').length ? $('meta[property="og:image"]').attr('content') : window.share_image,
        // optional:
        description: !!$('meta[property="og:description"]').length ? $('meta[property="og:description"]').attr('content') : decodeUri(description),
        emailBody: ""
    });

    $('.rrssb-email a').attr('href', `mailto:?subject=${subject}&body=${description}`)

    $.validator.messages.required = "* Campo obbligatorio.";
    $.validator.messages.email = "* Valore non valido."; 

    let autoComplete = new AutoComplete('#applicationForm');
    let formValidator = new FormValidator('#applicationForm');
    formValidator.initValidate();

    $('[name=project_budget]').on('keyup', function(e){
        let $f = $(e.currentTarget),
            v = $f.val();
        console.log(v, parseFloat(v));
        if(!!parseFloat(v)){
            $f.val(v.replace(',', '.'));
        }
    })

});